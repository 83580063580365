import { LazyLoadImage  } from "react-lazy-load-image-component";
import { API_URL } from "Constants";

interface Props{
    customClasses?:string,
    imgSrc:string,
    visibleByDefault?: boolean
}

export default function LazyImage({customClasses, imgSrc, visibleByDefault = false}: Props) {

  let placeholderSrc;

  if(imgSrc.indexOf("slide_imgs") >= 0){
    placeholderSrc = imgSrc.replace("slide_imgs/", "slide_imgs_placeholders/" ); 
  }else{
    placeholderSrc = imgSrc.replace("task_thumbnails/", "task_thumbnails_placeholders/" ); 
  } 

  return (
    <LazyLoadImage
        className={customClasses} 
        src={API_URL + imgSrc}
        placeholderSrc={API_URL + placeholderSrc}
        visibleByDefault={visibleByDefault}
  />
  )
}
