import Icon from "@mui/material/Icon";
import { useQuery } from 'react-query';
import { getTagsWithActiveTasks } from 'api/task/utils';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import Loader from "features/ui-components/Loader";
import TagIcon from '@mui/icons-material/Tag';
import { useAppDispatch } from "store/store";
import { setActiveTaskTag } from "store/features/feedSlice";
import { Dispatch, SetStateAction } from "react";

export default function OptionsTags({setActiveOptions}:{
  setActiveOptions: Dispatch<SetStateAction<string>>
}) {

  const dispatch = useAppDispatch();
  const { data: tagOptions, isLoading } = useQuery(["tags"], getTagsWithActiveTasks);
  
  return (
    <div className='filter-options tags pt-[39px] menu-fade-in'>

      <div className="absolute top-0 w-full text-center p-1 py-2 bg-primary text-white font-bold">
        <span>Tags</span>
      </div>

      {isLoading && 
        <div className="grid w-24 justify-center items-center py-2 px-8">
          <Loader customClasses='!text-white !w-6 !h-6'/>
        </div>
      }

      <div className="tag-options max-h-[215px] overflow-y-scroll">
        {tagOptions && tagOptions.map((tag: { value: string; icon_ligature: string }) => (

          <button 
            onClick={() => {
              setActiveOptions("");
              dispatch(setActiveTaskTag({
                value: tag.value,
                icon_ligature: tag.icon_ligature
              }));
            }}
            key={tag.value} 
            className="option-btn flex items-center py-2 px-1">
            <span className="flex items-center mr-[7px]">
              <Icon sx={{ fontSize: "18px" }}>{tag.icon_ligature}</Icon>
            </span>
            <span className="text-sm">
            {capitalizeFirstLetter(tag.value)}
            </span>
          </button>

        ))}

        {!isLoading &&
          <button 
            onClick={() => {
              setActiveOptions("");
              dispatch(setActiveTaskTag({
                value:"all", icon_ligature: "tag"
              }));
            }}
            className="option-btn py-2 border-0  text-center mt-2">
            <span>
              <TagIcon className="!h-5 !w-5 mb-1 mr-1"/>
            </span>
            <span className="text-sm mr-[15px]">
              All
            </span>
          </button>
        }
      </div>

    </div>
  )
}
