import {FallbackProps} from 'react-error-boundary'


export default function RenderErrorPage({error, resetErrorBoundary}: FallbackProps) {

  console.log(error)

  return (
    <div className="flex flex-col items-center justify-center h-full w-full dark:text-white bg-primary">
      <h1 className="w-full text-center text-2xl mb-4">Whoops! <br/> Something went wrong</h1>
      <button 
        onClick={resetErrorBoundary}
        className="text-2xl btn btn-submit bg-secondary ">
        Reload
      </button>
    </div>
  )
}
