import {api} from "./api";
 
interface updateAvatarResp{
  user_data:{
    avatar_file_name: string
  }
}

export const updateAvatar = async ({ avatar_file_name}: {avatar_file_name: string | undefined}) => {
    const data = new FormData();
    if(avatar_file_name !== undefined) data.append('avatar_file_name', avatar_file_name);

    const response = await api.post<updateAvatarResp>('/update-avatar', data,);

    let new_avatar_file_name = response.data?.user_data?.avatar_file_name;
    
    return new_avatar_file_name;
}

export const getHostLatestVersion = async () => {
  const response = await api.get(
    "/get-host-latest-version",
  );

  return response.data;

  
}
