import {lazy, Suspense} from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import Page404 from "./pages/Page404";
import { useAppSelector } from "./store/store";
import Feed from "./pages/feed/Feed";
import MainWrapper from "./features/ui-components/MainWrapper";
import PageLoader from './features/ui-components/PageLoader';
import { ErrorBoundary } from 'react-error-boundary';
import RenderErrorPage from './pages/error/RenderErrorPage';

function App() {

  const isLogged = useAppSelector((state) => state.auth.user?.id);
  const role = useAppSelector((state) => state.auth.user?.role)

  const isAdmin = role == "admin";
  const isTeacher = role == "teacher";

  const Activity = lazy(() => import('./pages/activity/Activity'))
  const Scores = lazy(() => import('./pages/scores/Scores'))
  const TaskPlayer = lazy(() => import('./pages/task/TaskPlayer'))
  const Login = lazy(() => import('./pages/auth/Login'))
  const Register = lazy(() => import('./pages/auth/register/Register'))
  const Settings = lazy(() => import('./pages/settings/Settings'))
  const CreateProfile = lazy(() => import('./pages/auth/CreateProfile'))
  const TaskCreateStep1 = lazy(() => import('./pages/task/TaskCreateStep1'))
  const TaskCreateStep2 = lazy(() => import('./pages/task/TaskCreateStep2'))
  const Dashboard = lazy(() => import('./pages/admin/Dashboard'))

  return (
    <ErrorBoundary fallbackRender={RenderErrorPage}>
      <MainWrapper>
        <Suspense fallback={<PageLoader/>}>          
          <Routes>
            <Route path="/" element={isLogged ?<Feed /> : <Navigate to={"/register"}/>} />
            <Route path="/activity" element={isLogged ?<Activity /> : <Navigate to={"/login"}/>} />
            <Route path="/scores" element={isLogged ?<Scores /> : <Navigate to={"/login"}/>} />

            <Route path="/:slug" element={isLogged ?<TaskPlayer /> : <Navigate to={"/login"}/>} />

            <Route path="/login" element={ !isLogged ? <Login /> : <Navigate to={"/"}/>} />
            <Route path="/register" element={ !isLogged ? <Register /> : <Navigate to={"/"}/>} />
            <Route path="/settings" element={isLogged ?<Settings /> : <Navigate to={"/login"}/>} />
            <Route path="/create-profile" element={isLogged ?<CreateProfile /> : <Navigate to={"/register"}/>} />

            <Route path="/new-task" element={isAdmin ?<TaskCreateStep1 /> : <Navigate to={"/login"}/>} />
            <Route path="/new-task-step-2" element={isAdmin ?<TaskCreateStep2 /> : <Navigate to={"/login"}/>} />
            <Route path="/dashboard" element={(isAdmin || isTeacher) ?<Dashboard /> : <Navigate to={"/login"}/>} />

            <Route path="*" element={<Page404 />} />
          </Routes>
        </Suspense>
      </MainWrapper>
    </ErrorBoundary>
  );
}

export default App;
