import {useState} from "react";
import OptionsTypes from "./options/OptionsTypes";
import TaskType from "features/task/card/TaskType";
import { useAppDispatch, useAppSelector } from "store/store";
import SearchIcon from '@mui/icons-material/Search';
import Icon from "@mui/material/Icon";
import { setIsSearchInputExtended } from "store/features/feedSlice";
import SearchInput from "./search/SearchInput";
import SearchResults from "./search/SearchResults";
import OptionsTags from "./options/OptionsTags";

export default function FiltersBar() {

  const dispatch = useAppDispatch();

  const [activeOptions, setActiveOptions] = useState("")

  const activeTaskType = useAppSelector((state) => state.feed.activeTaskType)
  const activeTaskTag = useAppSelector((state) => state.feed.activeTaskTag)
  const isSearchInputExtended = useAppSelector((state) => state.feed.isSearchInputExtended)
  const isSearching = useAppSelector((state) => state.feed.isSearching)
  const searchResults = useAppSelector((state) => state.feed.searchResults)


  const handleActiveOptions = (options:string) => {
    if(activeOptions !== options){
      setActiveOptions(options)
    }else{
      setActiveOptions("")
    }
  }

  const handleInputSearchVisibility = () => {
    dispatch(setIsSearchInputExtended(true))
  }


  return (
    <>
    {(isSearching || searchResults !== null ) && isSearchInputExtended &&
      <SearchResults />
    }
    <div className={`filters-bar text-white 
      ${isSearchInputExtended && 'px-4 lg:px-16'}`}>
      <div className="filters-wrapper">

        {/* TYPES: Reading, Writing, Speaking, Listening, All. */}

        {!isSearchInputExtended &&
          <div className="filter-field">
            <OptionsTypes
              activeOptions={activeOptions}
              setActiveOptions={setActiveOptions} />
            <button
              className={`filter-button types`}
              onClick={() => handleActiveOptions("types")}>
              <span className=" w-[22px] h-[22px] p-[3px] mr-2 mb-1 flex justify-center items-center rounded-full">
                <TaskType type={activeTaskType.toLowerCase()} />
              </span>
            </button>
          </div>}


        {/* TAGS: Builidings, Sports, etc */}

        {!isSearchInputExtended &&
          <div className="filter-field">
            {activeOptions == "tags" && 
              <OptionsTags setActiveOptions={setActiveOptions}/>
            }
            <button
              className={`filter-button tags`}
              onClick={() => handleActiveOptions("tags")}>
              <span className=" w-[22px] h-[22px] flex justify-center items-center rounded-full">
                <Icon sx={{ fontSize: "18px" }}>{activeTaskTag.icon_ligature}</Icon>
              </span>
              {/* <span className="">
              Tags
            </span> */}
            </button>
          </div>}


        {/* Search task by name */}

        <div className="filter-field">
          {!isSearchInputExtended &&
            <button
              className="filter-button search"
              onClick={() => {
                handleActiveOptions("search")
                handleInputSearchVisibility()
              }}
            >
              <SearchIcon sx={{ w: 16, h: 16 }} />
            </button>}

          {isSearchInputExtended &&
            <SearchInput />}
        </div>

      </div>
    </div>
    </>
  );
}
