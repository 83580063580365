import { api } from "../api";

//GET ALL TASK TAGS
export const getTags = async ({level}:{level:string}) => {

  let data = new FormData();
  if(level !== undefined){data.append('level', level)}

  const response = await api.post(
    "/get-tags",
    data
  );
  return response.data;
}

//GET ONLY TAGS CURRENTLY ASSIGNED TO A TASK
export const getTagsWithActiveTasks = async () => {  
  const response = await api.get(
    "/get-tags-with-active-tasks",
  );
  return response.data;
}

//GET TASK FORMATS
export const getFormats = async () => {

  const response = await api.get(
    "/get-formats",
  );
  return response.data;
}