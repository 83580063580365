import { api } from "../api";

export const searchTasks = async ({typed_text}: {typed_text:string}) => {

  let data = new FormData();
  if(typed_text !== undefined){data.append('typed_text', typed_text)}
  
  const response = await api.post(
    "/search-task",
    data,
  );
  return response.data;
}
