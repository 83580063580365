import { AddCircle, DarkMode, Logout, Settings, AccountCircle } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { useLogout } from "api/auth";
import { logout } from "store/features/authSlice";
import { useAppDispatch, useAppSelector } from "store/store";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleThemeToggle: () => void;
}

export default function NavbarAuthMenu({isOpen,setIsOpen,handleThemeToggle}: Props) {
  const role = useAppSelector((state) => state.auth.user?.role)
  const isAdmin = role == "admin" ? true : false;
  const hasProfile = useAppSelector((state) => state.auth.user?.email)

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clearAuthStorage = useLogout;
  const stateLogout = logout;

  const handleLogout = async () => {
    await clearAuthStorage();
    await dispatch(stateLogout());
    setIsOpen(false);
    navigate("/login");
  };

  return (
    <div
      className={`navbar-dropdown-menu transition-all duration-200 overflow-hidden
      ${isOpen ? "" : "!h-0"}
      ${isAdmin ? "h-[209px]" : "h-[157px]"} `}
    >
      <ul className="navbar-list block">
        {isAdmin &&
        <>
          <li className="navbar-li">
            <Link
              to="/new-task"
              onClick={() => setIsOpen(false)}
              className="navbar-link"
            >
              <AddCircle className="icon" />
              New Task
            </Link>
          </li>
        </>
        }

        <li
          className="navbar-li hover:cursor-pointer"
          onClick={() => {
            setIsOpen(false);
            navigate("/settings");
          }}
        >
          <button className="navbar-link block">
            <Settings className="icon" />
            Settings
          </button>
        </li>

        <li
          className="navbar-li hover:cursor-pointer"
          onClick={() => {
            handleThemeToggle();
            setIsOpen(false);
          }}
        >
          <button
            className="navbar-link block"
          >
            <DarkMode className="icon" />
            Toggle Dark theme
          </button>
        </li>
        {hasProfile && 
          <li className="navbar-li">
            <Link to="/login" onClick={() => handleLogout()} className="navbar-link">
              <Logout className="icon" />
              Logout
            </Link>
          </li>
        }
        {!hasProfile &&
          <li className="navbar-li">
            <Link to="/create-profile" onClick={() =>setIsOpen(false)} className="navbar-link">
              <AccountCircle className="icon" />
              Create Profile
            </Link>
          </li>
        }
        
      </ul>
    </div>
  );
}
