import { api } from "../api";

//GET ALL TASKS
interface Props{
  task_level: string, 
  task_type:string,
  task_tag:string,
  number_of_tasks:number
}

export const getTasks = async (props: Props) => {
  
  let data = new FormData();
  data.append('task_level', props.task_level.toLowerCase());
  data.append('task_type', props.task_type);
  data.append('task_tag', props.task_tag);
  data.append('number_of_tasks', props.number_of_tasks.toString()); 
  
  const response = await api.post(
    "/get-all-tasks",
    data,
  );
  return response.data;
}

//GET TASK'S SLIDES
export const getSlides = async (task_slug:string | undefined) => {
  const response = await api.get("/get-task-with-slides/" + task_slug);
  return response.data;
}

//CHECK LAST TIME USER DID TASK
export const getTaskLastDone = async (task_id:string | undefined) => {
  const response = await api.get("/get-task-last-done/" + task_id);
  return response.data;
}