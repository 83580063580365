import { UiSlice } from './features/uiSlice';
import {configureStore} from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AuthSlice } from './features/authSlice';
import { TaskCreateSlice } from './features/taskCreateSlice';
import { TaskPlayerSlice } from './features/taskPlayerSlice';
import { FeedSlice } from './features/feedSlice';
import { AdminSlice } from './features/adminSlice';


export const store = configureStore({
    reducer:{
        auth: AuthSlice.reducer,
        ui: UiSlice.reducer,
        taskCreate: TaskCreateSlice.reducer,
        taskPlayer: TaskPlayerSlice.reducer,
        feed: FeedSlice.reducer,
        admin: AdminSlice.reducer
    }
})

export const useAppDispatch:() => typeof store.dispatch = useDispatch;
export const useAppSelector:TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;


//CYPRESS CONFIG

interface CypressWithStore extends Cypress.Cypress {
    store?: typeof store;
  }
  
  declare global {
    interface Window {
      Cypress?: CypressWithStore;
    }
  }
  
  if (typeof window !== 'undefined' && window.Cypress) {
    window.Cypress.store = store;
  }