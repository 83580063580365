import React, {useEffect} from 'react'
import { useAppSelector } from 'store/store';
import Navbar from 'features/navbar/Navbar';
import TabsBar from 'features/tabsbar/TabsBar';

interface Props{
  children: React.ReactNode,
}

export default function MainWrapper({children}:Props) {

  const activeTheme = useAppSelector((state) => state.ui.themePref);
  const showNavbar = useAppSelector((state)=> state.ui.showNavbar);

  useEffect(() => {
    if (activeTheme === 'dark') {
      document.body.classList.add('bg-darkThemeBg', 'dark');
    } else {
      document.body.classList.remove('bg-darkThemeBg', 'dark')
    }
  }, [activeTheme])

  return (
    <div className={`${activeTheme == "dark" ? "bg-darkThemeBg dark" : "bg-white"} h-full`}>
      <div className={`app ${showNavbar && "pt-[52px]"} h-full`}>
        {showNavbar && <Navbar />}
        {children}
        {showNavbar && <TabsBar />}
      </div>
    </div>
  )
}
