import CircularProgress from "@mui/material/CircularProgress";
import { useAppSelector } from "store/store";

export default function Loader(
  {setMarginTop = "20px",
  customClasses = "!text-primary"} : 
  {setMarginTop?: string | number | null,
    customClasses?: string}) {

  const themePref = useAppSelector((state) => state.ui.themePref);
  const styles = {
      marginTop: setMarginTop
  }
  return <CircularProgress  className={customClasses}/>;
}
