import { calculateGrade } from "features/task/player/player_services/calculateGrade";
import { getGradeTextColor } from "features/task/player/player_services/getGradeColor";


export default function TaskResultGrade({result, customClasses}:{result:number, customClasses?:string}) {
  
  let grade = calculateGrade(result);

  return (
    <div> 
        <div 
            className={`font-bold bg-white text-lg rounded-full p-1 mr-1 min-w-[36px] flex justify-center
                ${getGradeTextColor(result)} ${customClasses}
            `}>
            {grade}
        </div>
    </div>
  )
}
