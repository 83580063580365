import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { storeThemePreferences } from "utils/storeThemePreferences";
import { DEFAULT_THEME, ACTIVITY_TASKS_DONE_SHOWN_INITIALLY } from "Constants";

const storageThemePref = localStorage.getItem("themePreference");
const themePrefvalue = storageThemePref ? storageThemePref : DEFAULT_THEME;


const initialState: { 
  themePref: string | null; 
  feedScrollPosition: number, 
  dashboardScrollPosition:number, 
  showNavbar: boolean,
  activityActiveTab: "all" | "user",
  allActivityNumberOfTasksDisplayed: number,
  userActivityNumberOfTasksDisplayed: number,
  pointsActiveTab: "all" | "user"
  } = 
  {
  themePref: themePrefvalue,
  feedScrollPosition: 0,
  dashboardScrollPosition: 0,
  showNavbar: true,
  activityActiveTab: "all",
  allActivityNumberOfTasksDisplayed: ACTIVITY_TASKS_DONE_SHOWN_INITIALLY,
  userActivityNumberOfTasksDisplayed: ACTIVITY_TASKS_DONE_SHOWN_INITIALLY,
  pointsActiveTab: "all"
};

export const UiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      let newValue = state.themePref == "dark" ? "light" : "dark";
      state.themePref = newValue;
      storeThemePreferences(newValue);
    },
    setFeedScrollPosition: (state, action: PayloadAction<number>) => {
        state.feedScrollPosition = action.payload;
    },
    setDashboardScrollPosition: (state, action: PayloadAction<number>) => {
      state.dashboardScrollPosition = action.payload;
    },
    setShowNavbar: (state, action: PayloadAction<boolean>) => {
      state.showNavbar = action.payload;
    },
    setActivityActiveTab: (state, action: PayloadAction<"all" | "user">) => {
      state.activityActiveTab = action.payload;
    },
    setPointsActiveTab: (state, action: PayloadAction<"all" | "user">) => {
      state.pointsActiveTab = action.payload;
    },
    setAllActivityNumberOfTasksDisplayed: (state, action: PayloadAction<number>) => {
      state.allActivityNumberOfTasksDisplayed = action.payload;
    },
    setUserActivityNumberOfTasksDisplayed: (state, action: PayloadAction<number>) => {
      state.userActivityNumberOfTasksDisplayed = action.payload;
    }
  },
});

export const { 
  toggleDarkMode, 
  setFeedScrollPosition,
  setDashboardScrollPosition, 
  setShowNavbar,
  setActivityActiveTab,
  setPointsActiveTab,
  setAllActivityNumberOfTasksDisplayed,
  setUserActivityNumberOfTasksDisplayed
} = UiSlice.actions;
