import React,{Dispatch, SetStateAction} from 'react'
import TaskType from 'features/task/card/TaskType'
import { useAppDispatch, useAppSelector } from 'store/store'
import { setActiveTaskType } from 'store/features/feedSlice';

export default function OptionsTypes({setActiveOptions, activeOptions}:{
  setActiveOptions: Dispatch<SetStateAction<string>>,
  activeOptions: string
}) {

  const dispatch = useAppDispatch();
  const activeType = useAppSelector((state) => state.feed.activeTaskType)

    const handleClick = (newType:string) => {
      setActiveOptions("")
      if(activeType !== newType){
        dispatch(setActiveTaskType(newType))
      }
    }

  return (
    <div className={`filter-options types !duration-300 ${activeOptions == 'types' ? 'menu-fade-in' : 'hidden'}`}>
      <div className="flex justify-center items-center p-1 py-2 bg-primary text-white font-bold">
        <span>Type</span>
      </div>

      <button 
        onClick={() => {handleClick("speaking")}} 
        className="option-btn">
        <span className="type-icon-span"><TaskType type='speaking'/></span>
        <span className="text-sm">Speaking</span>
      </button>

      <button 
        onClick={() => {handleClick("reading")}} 
        className="option-btn">
        <span className="type-icon-span"><TaskType type='reading'/></span>
        <span className="text-sm">Reading</span>
      </button>

      <button 
        onClick={() => {handleClick("listening")}} 
        className="option-btn">
        <span className="type-icon-span"><TaskType type='listening'/></span>
        <span className="text-sm">Listening</span>
      </button>

      <button 
        onClick={() => {handleClick("writing")}} className="
        option-btn pb-3">
        <span className="type-icon-span"><TaskType type='writing'/></span>
        <span className="text-sm">Writing</span>
      </button>

      <button 
        onClick={() => {handleClick("all")}}
        className="option-btn border-0">
        <span className="type-icon-span"><TaskType type='all'/></span>
        <span className="text-sm">All</span>
      </button>

    </div>
  )
}
