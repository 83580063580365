import { LoginProps, LoginResponse, RegisterProps } from "types/authTypes";
import { api } from "./api";

//LOGIN / SIGN IN

export const useLogin = async ({ host_url, email, password }: LoginProps) => {
  
  const response = await api.post<LoginResponse>(
    "/login",
    { host_url, email, password }
  );
  
  if (response.data?.access_token) {
    await localStorage.setItem(
      "accessToken",
      JSON.stringify(response.data.access_token)
    );
    await localStorage.setItem("userData", JSON.stringify(response.data.user));
  }

  return response
};


//CHECK ACCESS CODE

export const useCheckAccessCode = async ({enteredCode}: {enteredCode:string}) => {
  const response = await api.post(
    "/check-access-code",
    {entered_code: enteredCode, host_url: window.location.host}
  );
  return response;
};


//REGISTER / SIGN UP

export const useRegister = async ({name, level, access_code}: RegisterProps) => {
  console.log("IN useRegister");
  
  const response = await api.post(
    "/register",
    { name, 
      level, 
      host_url: window.location.host,
      access_code
    }
  );

  console.log("register resp", response);
  
  if (response.data?.access_token) {
    await localStorage.setItem(
      "accessToken",
      JSON.stringify(response.data.access_token)
    );
    await localStorage.setItem("userData", JSON.stringify(response.data.user));
    console.log("localstorage was set");
  }

  return response;
};


//CREATE PROFILE 

export const createProfile = async ({email, password, password_confirmation}: 
  {email:string, password:string, password_confirmation:string}) => {

  let data = new FormData();
  if(email !== undefined){data.append('email', email)}
  if(password !== undefined){data.append('password', password)}
  if(password_confirmation !== undefined){data.append('password_confirmation', password_confirmation)}
  
  const response = await api.post(
    "/create-profile",
    data,
  );
  return response.data;
}


//REGISTER GUEST

export const useRegisterGuest = async () => {
  const response = await api.post(
    "/register-guest",
  );

  if (response.data?.access_token) {
    localStorage.setItem(
      "accessToken",
      JSON.stringify(response.data.access_token)
    );
    localStorage.setItem("userData", JSON.stringify(response.data.user));
  }

  return response;
};


export const useLogout = async () => {
  await localStorage.removeItem("accessToken");
  await localStorage.removeItem("userData");
};
