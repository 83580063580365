import axios from "axios";
import { API_URL } from "Constants";

let initialRender_tokenStr = localStorage.getItem("accessToken");
let initialRender_accessToken = initialRender_tokenStr ? JSON.parse(initialRender_tokenStr) : null;

export const api = axios.create({
   baseURL: API_URL + "/api",
   headers: {
      Authorization: `Bearer ${initialRender_accessToken}`,
      "Content-Type": "application/json",
   },
});

api.interceptors.request.use(
   (req) => {
      if (initialRender_accessToken == null) {
         const token = localStorage.getItem("accessToken");
         if (token) {
            req.headers.Authorization = `Bearer ${JSON.parse(token)}`;
         }
      }
      return req;
   },
   (error) => Promise.reject(error)
);
