import { Dashboard as DashboardIcon } from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from 'react-router-dom';
import { setDashboardScrollPosition, setFeedScrollPosition } from 'store/features/uiSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import FiltersBar from 'pages/feed/filters/FiltersBar';

export default function TabsBar() {

const navigate = useNavigate();
const dispatch = useAppDispatch();

const isAdminOrTeacher = useAppSelector((state) => state.auth.user?.role) != "student";

const activePath = window.location.pathname;
const isFeed = activePath == "/";

const path = window.location.pathname;
if(path !== "/" && 
    path !== "/media" &&
    path !== "/activity" &&
    path !== "/scores" &&
    path !== "/dashboard")
{
    return <></>
}

  return (
    <>
    {isFeed && <FiltersBar />}
    <div className="tabs-bar">
          <div className={`tab ${isFeed && "active"} ${isAdminOrTeacher && '!col-span-6'}`}
              onClick={() => {
                  dispatch(setDashboardScrollPosition(window.scrollY));
                  navigate("/");
              } }>
            <div className="text-center">
                <HomeIcon />
                <label className="label">Home</label>
            </div>
          </div>

          {!isAdminOrTeacher && <>
                <div className={`tab ${activePath == "/activity" && "active"}`}
                    onClick={() => {
                        dispatch(setFeedScrollPosition(window.scrollY));
                        navigate("/activity");
                    } }>

                <div className="text-center">
                    <FormatListBulletedIcon />
                    <label className="label">Activity</label>
                </div>
                </div>
                <div className={`tab ${activePath == "/scores" && "active"}`}
                    onClick={() => {
                        dispatch(setFeedScrollPosition(window.scrollY));
                        navigate("/scores");
                    } }>
                <div className="text-center">
                    <StarIcon sx={{ color: "gold !important"}}/>
                    <label className="label">Scores</label>
                </div>
                    
                </div>
            </>}

            {isAdminOrTeacher && <>
                <div className={`tab ${activePath == "/dashboard" && "active"} !col-span-6`}
                onClick={() => {
                    dispatch(setFeedScrollPosition(window.scrollY));
                    navigate("/dashboard");
                } }>
                <DashboardIcon />
                </div>
            </>}
      </div>
      </>
  )
}
