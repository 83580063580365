import HeadphonesIcon from "@mui/icons-material/Headphones";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import CreateIcon from "@mui/icons-material/Create";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MicIcon from "@mui/icons-material/Mic";

interface Props{
  type: string, 
  customClasses?:string 
}

function TaskType({ type, customClasses }: Props) {

  switch (type) {
    case "listening":
      return (
        <>
          <HeadphonesIcon className={`type-icon ${customClasses}`}/>
        </>
      );

    case "writing":
      return (
        <>
          <CreateIcon className={`type-icon ${customClasses}`}/>
        </>
      );

      case "speaking":
        return (
          <>
            <MicIcon className={`type-icon ${customClasses}`}/>
          </>
        );

      case "all":
        return (
          <>
            <TextSnippetIcon className={`type-icon ${customClasses}`}/>
          </>
        );

    default:
      return (
        <>
          <MenuBookIcon className={`type-icon ${customClasses}`}/>
        </>
      );
  }
}


export default TaskType