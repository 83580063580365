import {memo} from 'react'
import { TaskProps } from 'types/taskTypes';
import TaskCard from './TaskCard';

interface Props{
  tasks: TaskProps [],
}

function TaskCardList({tasks}:Props){
  return (
    <>
      {tasks.map((task: any) => (
          <TaskCard
            key={task.id}
            data={task} />
        ))}
    </>
  )
}

export default memo(TaskCardList)