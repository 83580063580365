import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SlideProps, TaskCreateProps } from "types/taskTypes";

const initialState: {
  id: string;
  title: string;
  type: string;
  format?: {
    id:string;
    value:string;
    instructions:string;
  };
  thumbnail_url: string;
  slides: Array<SlideProps>;
} = {
  id: "",
  type: "",
  format: {
    id:"",
    value:"",
    instructions:""
  },
  title: "",
  thumbnail_url: "",
  slides: [],
};

export const TaskCreateSlice = createSlice({
  name: "taskCreate",
  initialState,
  reducers: {
    setStep1Data: (state, action: PayloadAction<TaskCreateProps>) => {
      state.id = action.payload.id;
      state.type = action.payload.type;
      state.format = action.payload.format;
      state.title = action.payload.title;
      state.thumbnail_url = action.payload.thumbnail_url;
    },
    addSlide: (state, action: PayloadAction<SlideProps>) => {
      state.slides.push(...state.slides, {
        prompt: action.payload.prompt, 
        img_url: action.payload.img_url, 
        correct_answer: action.payload.correct_answer
      })
    },
  },
});

export const { setStep1Data, addSlide} = TaskCreateSlice.actions;