import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: {
    selectedLevel: string;
} = {
    selectedLevel: "b1",
};

export const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setSelectedLevel: (state, action: PayloadAction<string>) => {
        state.selectedLevel = action.payload;
    }
  },
});

export const { setSelectedLevel} = AdminSlice.actions;